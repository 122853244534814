<!-- <p class="heading section-header">Contact</p> -->

<div class="form-background">
    <div class="heading-container">
        <p class="contact-heading">Contact Us</p>
    </div>
    <div class="form-container">
        <div class="column-info">
            <p class="contact-action">Ready?</p>
            <p class="contact-subheading">
                Give me a call and I'll be happy to get back to you ASAP
            </p>
            <div class="logo"></div>
        </div>
    </div>
</div>

<ng-template #successDialog>
    <h2 mat-dialog-title>Thank You!</h2>
    <p mat-dialog-content>We'll get in touch with you ASAP</p>
    <div mat-dialog-actions>
        <button mat-button mat-dialog-close>Close</button>
    </div>
</ng-template>

<ng-template #failDialog>
    <h2 mat-dialog-title>Something went wrong</h2>
    <p mat-dialog-content>Please try again</p>
    <div mat-dialog-actions>
        <button mat-button mat-dialog-close>Close</button>
    </div>
</ng-template>
