import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ContactMessage } from 'src/lib/models/ContactMessage';
import { EmailService } from '../../services/emailService.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  @Input() 
  contactMessage: ContactMessage = {
    name: '',
    email: '',
    message: '',
  }
  
  contactForm: FormGroup;

  @ViewChild('successDialog')
  successDialog: TemplateRef<any>;
  @ViewChild('failDialog')
  failDialog: TemplateRef<any>;

  constructor(
    private emailService: EmailService,
    private dialogService: MatDialog,
  ) { }

  ngOnInit(): void {
    this.contactForm = new FormGroup({
      name: new FormControl(
        '',
        [Validators.required, Validators.minLength(2), Validators.maxLength(255)],
      ),
      email: new FormControl(
        '',
        [Validators.required, Validators.email],
      ),
      message: new FormControl(
        '',
        [Validators.maxLength(800)],
      ),
    });
  }

  get name() { return this.contactForm.get('name'); }
  get email() { return this.contactForm.get('email'); }
  get message() { return this.contactForm.get('message'); }

  onSubmitForm() {
    if(this.contactForm.invalid) {
      return;
    }
    
    const contactMessage: ContactMessage = {
      name: this.name.value,
      email: this.email.value,
      message: this.message.value,
    }

    console.log('Contact Form Submitted');

    this.emailService.sendEmail(contactMessage)
    .then(() => this.dialogService.open(
      this.successDialog, 
      { panelClass: 'custom-dialog-box' },
    ))
    .catch(() => this.dialogService.open(
      this.failDialog,
      { panelClass: 'custom-dialog-box' },
    ));
  }

}
