<div class="footer">
    <div class="info-container">
        <a href="https://www.facebook.com/SunValleyDrivingSchool">
            <div class="facebook-logo"></div>
        </a>
        <div class="main-info">
            <p class="name">Sun Valley Driving School Ltd.</p>
            <p class="email">sunvalleydriving@gmail.com</p>
            <p class="phone">250-258-5484</p>
        </div>
    </div>
    <hr>
    <div class="copyright">
        Copyright &copy; 2021 Sun Valley Driving School Ltd. All rights reserved.
    </div>
</div>
