import axios, { AxiosRequestConfig, AxiosResponse, AxiosError, Method } from 'axios';
import { Injectable } from '@angular/core';
import { HttpRequestError, HttpRequestModel } from './models/HttpRequestModel';

interface IHttpClientService {
    httpRequest<T>(config: HttpRequestModel, callback: (data: T|HttpRequestError) => void);
}

@Injectable({
    providedIn: 'root'
})
export class HttpClientService implements IHttpClientService {
    httpRequest = async <T>(config: HttpRequestModel, callback: (data: T|HttpRequestError) => void) => {
        console.log('mapping httpRequestModel to AxiosRequestConfig');
        const axiosConfig: AxiosRequestConfig = this.httpRequestMapper(config);
        console.log('making axios request')
        axios(axiosConfig)
        .then((response: AxiosResponse) => {
            callback(response.data)
        })
        .catch((error: AxiosError) => {
            console.log(`http client error: ${error.message}`);
            const requestError: HttpRequestError = { 
                message: error.message, 
            };
            callback(requestError);
        });
    }

    // returns an Axios RequestConfig
    httpRequestMapper = (request: HttpRequestModel): AxiosRequestConfig => {
        const axiosRequest: AxiosRequestConfig = {
            method: request.method as Method,
            url: request.url,
            headers: request.headers,
            data: request.data,
        }

        return axiosRequest;
    };
}
