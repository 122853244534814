import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LessonPackage } from '../classFourDialog/ClassFourDialog.component';

@Component({
  selector: 'mg-class-five-modal',
  templateUrl: './classFiveDialog.component.html',
  styleUrls: ['./classFiveDialog.component.scss']
})
export class ClassFiveDialogComponent implements OnInit {
  readonly lessonPackages: LessonPackage[] = [
    {
      title: 'Basic Pkg. Class 5/7 Driver Training',
      description: '',
      information: [
        '3 hours on-road (2 sessions)',
        'Basic Training - $200 GST Included',
      ],
    },
    {
      title: 'Regular Pkg. Class 5/7 Driver Training',
      description: '',
      information: [
        '7.5 hours on-road (5 sessions)',
        'Regular Package - $500 (5% off = $475) GST Included',
        '5% discount if full amount paid in advance',
      ],
    },
    {
      title: 'Complete Pkg. Class 5/7 Driver Training',
      description: '',
      information: [
        '15 hours on-road (10 sessions)',
        'Complete Package - $1000 (10% off = $900) GST Included',
        '10% discount if full amount paid in advance',
      ],
    },
    {
      title: 'Road Test Pkg. Class 5/7 Driver Training',
      description: 'Includes school car use and a warm up lesson.',
      information: [
        'Road Test Package - $250 GST Included',
      ],
    },
    {
      title: 'Individual Lessons for Class 5/7 Training',
      description: '',
      information: [
        '$100 for a 90 minute session. GST included.',
      ],
    },
  ];

  constructor(private dialog: MatDialog) { }

  ngOnInit(): void {
  }

  onClose() {
    this.dialog.closeAll();
  }

}
