<div class="fb-feed-wrapper">
    <div 
        class="fb-page" 
        data-href="https://www.facebook.com/SunValleyDrivingSchool" data-tabs="timeline" 
        data-width="450" 
        data-height="500" 
        data-small-header="true" 
        data-adapt-container-width="true" 
        data-hide-cover="false" 
        data-show-facepile="false">
            <blockquote 
                cite="https://www.facebook.com/SunValleyDrivingSchool" class="fb-xfbml-parse-ignore">
                    <a href="https://www.facebook.com/SunValleyDrivingSchool">
                        Sun Valley Driving School
                    </a>
            </blockquote>
    </div>
</div>