import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

export interface LessonPackage {
  title: string,
  description: string,
  information: string[],
}

@Component({
  selector: 'app-class-four-dialog',
  templateUrl: './ClassFourDialog.component.html',
  styleUrls: ['./ClassFourDialog.component.scss']
})
export class ClassFourDialogComponent implements OnInit {
  readonly lessonPackages: LessonPackage[] = [
    {
      title: 'Basic Package Class 4 Ristricted Driver Training',
      description: '',
      information: [
        '1  hr.  PreTrip + on-road Training. $100 GST included',
        '2  hr.  PreTrip + on-road Training. $150 GST included',
      ],
    },
  ];

  constructor(private dialog: MatDialog) { }

  ngOnInit(): void {
  }

  onClose() {
    this.dialog.closeAll();
  }

}
