// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  googleApiKey: 'AIzaSyDwfJgVzfjRhF8FjL_9vk5FllAe25ZARes',

  // email specifics
  sendEmailUrl: 'https://api.emailjs.com/api/v1.0/email/send',
  serviceId: 'service_sun_valley',
  templateId: 'student_inquiry_email',
  emailUserId: 'user_wHmVtcbAaF5d0MpNuEfeZ',

  //facebook social plugin integration
  facebookPagePluginKey: '2142317295945454',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
