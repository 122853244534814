import { Component, OnInit } from '@angular/core';

interface policy {
  heading: string;
  descriptions: string[];
}

@Component({
  selector: 'app-policy',
  templateUrl: './policy.component.html',
  styleUrls: ['./policy.component.scss']
})
export class PolicyComponent implements OnInit {
  readonly policies: policy[] = [
    {
      heading: 'Areas served',
      descriptions: ['Our school operates in the Kelowna, Vernon and Penticton areas.']
    },
    {
      heading: 'Clean Protocol',
      descriptions: ['All students are asked to wear a mask during each lesson and apply hand sanitizer upon entering the instructor\'s vehicle.']
    },
    {
      heading: 'Pickup',
      descriptions: ['Students can request to be picked up and dropped off for each lesson at the discretion of the instructor.']
    },
    {
      heading: 'Payment',
      descriptions: ['All payments must be made prior to every driving lesson']
    },
    {
      heading: 'School Car Use',
      descriptions: ['The Sun Valley Driving School training car can be booked for use on a road test for an additional fee.', 'Fee for school car use is $100 for Kelowna or Vernon, $200 for Pentiction. Taxes in.', 'If the student would like to use the school car for a road test & is not a former student of Sun Valley Driving School Ltd., a driving skills assessment lesson of 2 hours will be carried out']
    },
    {
      heading: 'Items To Bring',
      descriptions: ['The student must carry their valid B.C. drivers license during each driving lesson.', 'If the student is required to wear prescription glasses or contacts while driving, they must wear them during each lesson.', 'Students must also wear appropriate footwear. Flip-flops, high heels, etc... will not be allowed.']
    },
    {
      heading: 'Pre-booking Road Test',
      descriptions: ['The student must notify Sun Valley Driving School Ltd. at the time of booking a driving lesson, if they have already booked a road test with ICBC.']
    },
    {
      heading: 'Student Assessment',
      descriptions: ['The student will be refused a driving lesson if the instructor has any reason to believe that the student may be under the influence of alcohol, drugs or mental stress.']
    },
    {
      heading: 'Cancellation Of Lesson',
      descriptions: ['The student must notify the school of any cancellations at least 24 hrs in advance to avoid incurring a late cancellation fee of $50.', 'Cancellation may be done by e-mail, phone or text.', 'If the student does not show for a lesson, they may be charged a fee of $50.']
    },
    {
      heading: 'Cancellation Of Road Test',
      descriptions: ['Cancelling a road test appointment booked by the school will require 72 hours notice, as per ICBC\'s cancellation policy', 'Failure to give Sun Valley Driving School Ltd. 72 hours notice will result in a late cancellation fee charged by ICBC.']
    },
    {
      heading: 'Passengers',
      descriptions: ['Sorry, passengers are not allowed with the exeption of other instructors.']
    },
    {
      heading: 'Refund',
      descriptions: ['There will be no refund for lessons that have already taken place.']
    },
  ]


  constructor() { }

  ngOnInit(): void {
  }

}
