<p class="heading class-header">Lessons</p>

<div class="container">
    <p class="intro">
        We want to bring you a comprehensive training program. We'll cover all
        the fundamentals, build good driving habits and improve weak areas of
        performance. Our top priority is to train students to become safe and 
        competent drivers. No GLP yet.
    </p>

    <div class="card-container">
        <div class="card">
            <div class="card-icon card-icon--car"></div>
            <div class="card-image card-image--class5"></div>
            <div class="card-body">
                <p class="card-title">Passenger Car Coaching</p>
                <p class="card-subheading">Class 5 & 7</p>
                <p class="card-description">
                    We provide a thorough training program for students to learn the rules of the road and drive confidently.
                </p>
            </div>
            <button class="card-button" (click)="openClass5Dialog()">SEE MORE</button>
        </div>

        <div class="card">
            <div class="card-icon card-icon--ambulance"></div>
            <div class="card-image card-image--class4"></div>
            <div class="card-body">
                <p class="card-title">Commercial Coaching</p>
                <p class="card-subheading">
                    Class 4 Restricted & Unrestricted
                </p>
                <p class="card-description">
                    We also provide pre-trip inspection training and refresher lessons for commerical licenses.
                </p>
            </div>
            <button class="card-button" (click)="openClass4Dialog()">
                SEE MORE
            </button>
        </div>
    </div>


</div>









