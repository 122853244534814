<div>
    <div class=header>
        <div class="contact-info">
            <div fxLayout="row">
                <mat-icon>call</mat-icon>
                <p class="info-text">250-258-5484</p>
            </div>
            <div fxLayout="row">
                <mat-icon>email</mat-icon>
                <p class="info-text">sunvalleydriving@gmail.com</p>
            </div>
        </div>
    </div>

    <nav 
        class="navbar navbar-expand-lg navbar-dark scrolling-navbar intro-fixed-nav">

        <button class="navbar-toggler" type="button" (click)="onMenuToggle()">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" [class.show]="navbarOpen">
            <ul class="navbar-nav mr-auto">
                <li class="nav-item active waves-light" mdbWavesEffect>
                    <a class="nav-link">
                        Home
                        <span class="sr-only">(current)</span>
                    </a>
                </li>
                <li 
                    class="nav-item waves-light" 
                    (click)="scroll(intro)" 
                    mdbWavesEffect>
                    <a class="nav-link">About</a>
                </li>
                <li 
                    class="nav-item waves-light" 
                    (click)="scroll(lessons)" 
                    mdbWavesEffect>
                    <a class="nav-link">Lessons</a>
                </li>
                <li 
                    class="nav-item waves-light" 
                    (click)="scroll(contact)" 
                    mdbWavesEffect>
                    <a class="nav-link">Contact</a>
                </li>
            </ul>
        </div>
    </nav>

    <div class="view">
        <div class="full-bg-img">
            <div class="logo"></div>
        </div>
    </div>

</div>

<div #intro>
    <app-introduction></app-introduction>
</div>

<div #lessons>
<app-lessons></app-lessons>
</div>

<div #policy>
<app-policy></app-policy>
</div>

<div #contact>
<app-contact></app-contact>
</div>

<app-location></app-location>

<app-footer></app-footer>

<router-outlet></router-outlet>