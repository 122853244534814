import { Component, HostListener, OnInit } from '@angular/core';
import {SizeConstants} from 'src/app/globalConstants/sizeConstants';

@Component({
  selector: 'app-facebook-page-feed',
  templateUrl: './facebookPageFeed.component.html',
  styleUrls: ['./facebookPageFeed.component.scss']
})
export class FacebookPageFeedComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}


