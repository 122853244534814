import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ContactMessage } from 'src/lib/models/ContactMessage';
import { HttpRequestError, HttpRequestModel } from '../lib/models/HttpRequestModel';
import { HttpClientService } from '../lib/HttpClientService';

interface IEmailService {
  sendEmail(request: ContactMessage): Promise<void>;
}

@Injectable({
  providedIn: 'root'
})
export class EmailService implements IEmailService {
  constructor(
    private httpClient: HttpClientService,
  ) { }

  sendEmail(contactMessage: ContactMessage): Promise<void> {
    const data = {
      service_id: environment.serviceId,
      template_id: environment.templateId,
      user_id: environment.emailUserId,
      template_params: {
        ...contactMessage
      }
    };

    const request: HttpRequestModel  = {
      method: 'post',
      url: environment.sendEmailUrl,
      headers: { contentType: 'application/json' },
      data: data,
    };

    return new Promise((resolve, reject) => {
      this.httpClient.httpRequest(request, (data: any|HttpRequestError) => {
        console.log('send email request complete');
        if((data instanceof HttpRequestError)) {
          reject();
        } else {
          resolve();
        }
      });
    });
  }
}
