<div class="alert-banner">
    <p class="alert-title">Covid 19 Alert</p>
    <p class="alert-info">We have increased the number of times our classrooms and vehicles are disinfected between training sessions. Personal Protective Equipment (PPE) can be provided for in-vehicle lessons should the student not have access to their own equipment to reduce the risk of any airborne particle transmission. Prior to the lesson, we will also complete a health screening questionnaire to ensure that the student is healthy and able to participate in the lesson.</p>
</div>

<p class="heading class-header">About Us</p>

<img src="../../assets/corolla-side-logo.png" class="lesson-banner">

<div class="info-container">
    <div class="profile-image"></div>

    <p class="intro">
        Instructor Baljit has more than 30 years of on the road professional driving experience with a range of different vehicles. He's driven not just in British Columbia but all of over North America. He has now dedicated himself to Sun Valley Driving School in the Kelowna area. You can feel confident and safe in his coaching.
    </p>
</div>

