import { AppComponent } from './app.component';
import { IntroductionComponent } from './introduction/introduction.component';
import { LessonsComponent } from './lessons/lessons.component';
import { ContactComponent } from './contact/contact.component';
import { LocationComponent } from './location/location.component';
import { FooterComponent } from './footer/footer.component';
import { PolicyComponent } from './policy/policy.component';
import { ClassFiveDialogComponent } from './classFiveDialog/ClassFiveDialog.component';
import { ClassFourDialogComponent } from './classFourDialog/ClassFourDialog.component';

// External Dependencies
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { OverlayModule } from '@angular/cdk/overlay';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDialogModule } from '@angular/material/dialog';
import { CdkAccordionModule } from '@angular/cdk/accordion'; 
import { MatExpansionModule } from '@angular/material/expansion'; 
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { FacebookPageFeedComponent } from './facebookPageFeed/facebookPageFeed.component';


@NgModule({
  declarations: [
    AppComponent,
    IntroductionComponent,
    LessonsComponent,
    ContactComponent,
    LocationComponent,
    FooterComponent,
    PolicyComponent,
    ClassFiveDialogComponent,
    ClassFourDialogComponent,
    FacebookPageFeedComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MDBBootstrapModule.forRoot(),
    BrowserAnimationsModule,
    MatIconModule,
    MatCardModule,
    OverlayModule,
    FlexLayoutModule,
    MatDialogModule,
    CdkAccordionModule,
    MatExpansionModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
