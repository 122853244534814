import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss']
})
export class LocationComponent implements OnInit {
  mapSrc: string;
  safeMapSrc: SafeResourceUrl;

  constructor(private sanitizer: DomSanitizer) {
    this.mapSrc = "https://www.google.com/maps/embed/v1/place?q=place_id:ChIJvbBmGrHtfVMRXCWJJOzw4Hw&key=AIzaSyDwfJgVzfjRhF8FjL_9vk5FllAe25ZARes";
  }

  ngOnInit(): void {
    this.safeMapSrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.mapSrc);
  }

}
