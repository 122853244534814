<p class="heading class-header">Policies</p>

<mat-accordion class="accordion">
    <mat-expansion-panel  
        class="accordion-panel"
        *ngFor="let policy of policies">
        <mat-expansion-panel-header class="accordion-panel-header">
            <mat-panel-title>{{policy.heading}}</mat-panel-title>
        </mat-expansion-panel-header>
        <mat-expansion-panel-body 
            class="accordion-panel-body">
            <ul>
                <li *ngFor="let description of policy.descriptions">
                    {{description}}
                </li>
            </ul>
        </mat-expansion-panel-body>
    </mat-expansion-panel>
</mat-accordion>




