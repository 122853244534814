import { FocusMonitor } from '@angular/cdk/a11y';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ClassFiveDialogComponent } from '../classFiveDialog/ClassFiveDialog.component';
import { ClassFourDialogComponent } from '../classFourDialog/ClassFourDialog.component';

@Component({
  selector: 'app-lessons',
  templateUrl: './lessons.component.html',
  styleUrls: ['./lessons.component.scss']
})
export class LessonsComponent implements OnInit {
  isOverlayOpen$: Observable<boolean>;
  isOverlayOpenAsync$: Observable<boolean>;

  constructor(
    private focusMonitor: FocusMonitor,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
  }

  openClass5Dialog() {
    this.dialog.open(ClassFiveDialogComponent, {
      panelClass: 'custom-class-dialog',
    });
  }

  openClass4Dialog() {
    this.dialog.open(ClassFourDialogComponent, {
      panelClass: 'custom-class-dialog',
    });
  }

}
