<div class="dialog-container">
    <div class="info-container">
        <mat-icon
            class="close-icon"
            (click)="onClose()">
            close
        </mat-icon>

        <div class="header-container">
            <p class="header">Class 4 Restricted / Unrestricted</p>
        </div>

        <div
            class="package-container"
            *ngFor="let package of lessonPackages">
            <p class="package-title">{{package.title}}</p>
            <p class="package-description">{{package.description}}</p>
            <ul>
                <li *ngFor="let point of package.information">
                    {{point}}
                </li>
            </ul>
        </div>
    </div>


</div>

